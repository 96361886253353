<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มผู้ซื้อ / ผู้ชำระเงิน
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <h3 class="mb-7">
          <i class="bi bi-person-fill" style="font-size: 20px"></i>
          ผู้ซื้อ / ผู้รับเงิน
        </h3>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >รหัสลูกค้า</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_code"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="amount" class="required form-label col-sm-10"
            >ชื่อกิจการ / ชื่อบุคคล</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_name"
              class="form-control"
              name="amount"
              id="amount"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="form-label col-sm-10"
            >เลข 13 หลัก</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_id_card"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="13"
              class="form-control"
              name="docNumber"
              id="docNumber"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->

        <div class="row d-flex justify-content-center mb-4">
          <label for="input1" class="col-sm-10">ประเภทกิจการ</label>
          <div class="col-sm-10">
            <div
              class="form-check form-check-inline form-check-custom form-check-solid"
            >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="ไม่มี"
                v-model="form.customer_branch"
                id="flexRadioChecked"
                checked="checked"
              />
              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >หน่วยรถขาย</label
              >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="สนง. ใหญ่"
                v-model="form.customer_branch"
                id="flexRadioChecked"
                checked="checked"
              />
              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >ใบสั่งจอง</label
              >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="สาขา"
                v-model="form.customer_branch"
                id="flexRadioChecked2"
              />
              <label
                class="form-check-label"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >ลูกค้าขาจร</label
              >
            </div>
          </div>

          <div v-if="form.customer_branch === 'สาขา'" class="col-sm-10">
            <div class="col-sm-12">
              <input
                v-model="form.customer_sub_branch_name"
                type="text"
                class="form-control"
                id="input1"
                placeholder="กรณีเป็นสาขา โปรดระบุเลขที่สาขา"
                :style="
                  isSubmit
                    ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                    : ''
                "
              />
            </div>
          </div>
        </div>

        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="required form-label col-sm-10"
            >สาขา</label
          >
          <div class="col-sm-10">
            <select
              v-model="form.customer_company_type"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option>บริษัทมหาชนจำกัด</option>
              <option selected>บริษัทจำกัด</option>
              <option>ห้างหุ้นส่วนจำกัด</option>
              <option>ห้างหุ้นส่วนสามัญ</option>
              <option>ร้านค้า</option>
              <option>บุคคลธรรมดา</option>
              <option>มูลนิธิ</option>
              <option>สมาคม</option>
              <option>กิจการร่วมค้า</option>
              <option>อื่นๆ</option>
            </select>
            <div class="invalid-feedback text-end">กรุณาเลือก</div>
          </div>

          <div
            v-if="form.customer_company_type === 'อื่นๆ'"
            class="col-sm-10 mt-4"
          >
            <input
              v-model="form.customer_company_type_desc"
              type="number"
              class="form-control"
              name="amount"
              id="amount"
              placeholder="กรณีอื่นๆ โปรดระบุ"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>


        <div class="row d-flex justify-content-center mb-4">
          <label for="docNumber" class="required form-label col-sm-10"
            >สถานะ</label
          >
          <div class="col-sm-10">
            <select
              v-model="form.ETC"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>nomal</option>
              <option>อื่นๆ</option>
            </select>
            <div class="invalid-feedback text-end">กรุณาเลือก</div>
          </div>

          <div
            v-if="form.customer_company_type === 'อื่นๆ'"
            class="col-sm-10 mt-4"
          >
            <input
              v-model="form.customer_company_type_desc"
              type="number"
              class="form-control"
              name="amount"
              id="amount"
              placeholder="กรณีอื่นๆ โปรดระบุ"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <!-- -->

        <hr style="color: LightGray" class="mt-10 mb-8" />










        <h3 class="mb-7">
          <i class="bi bi-file-earmark-text" style="font-size: 20px"></i>
          ข้อมูลทั่วไป
        </h3>

        <div class="row d-flex justify-content-center mb-4">
          <label for="discountPerUnit" class="required form-label col-sm-10"
            >สายวิ่ง</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_tel"
              class="form-control"
              id="discountPerUnit"
              required
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >ประเภทร้านค้า</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_email"
              class="form-control"
              id="discountPerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >ภาค</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_email"
              class="form-control"
              id="discountPerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >เขต</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.customer_email"
              class="form-control"
              id="discountPerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>



        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="pricePerUnit" class="form-label col-sm-10">ที่อยู่</label>
          <div class="col-sm-10">
            <textarea
              v-model="form.customer_address"
              type="number"
              class="form-control"
              id="pricePerUnit"
              name="pricePerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import saleApi from "@/api/sale/";

export default {
  setup() {
    document.title = "bem-document-demo เพิ่มผู้ซื้อ / ผู้ชำระเงิน";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      customer_code: "",
      customer_name: "",
      customer_id_card: "",
      customer_branch: "สนง. ใหญ่",
      customer_sub_branch_name: "",
      customer_company_type: "บริษัทจำกัด",
      customer_company_type_desc: "",
      customer_tel: "",
      customer_email: "",
      customer_address: "",
      status: "0",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),

  methods: {
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          companyId: parseInt(localStorage.getItem("companyId")),
        };

        createResponse = await saleApi.customer.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/sale/SA1",
              query: {},
            });
          });
          this.loading = false;
        } else if (createResponse.response_status === "FAILED") {
          Swal.fire({
            icon: "error",
            title: `เบอร์ ${this.form.customer_tel} ถูกใช้แล้ว`,
            showConfirmButton: false,
            timer: 2500,
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "เพิ่มไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.customer_name
        ? (this.isValid = false)
        : !this.form.customer_company_type
        ? (this.isValid = false)
        : !this.form.customer_tel
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/sale/SA1",
        query: {},
      });
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
